<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      page: 1,
      perPage: 5,
      pages: [],
      slice: [],
      submited: false,
      spanView: false,
      slices: {},
      data: {},
      title: "Gestion ecolages",
      items: [
        {
          text: "Ecolages",
          href: "/",
        },
        {
          text: "Gerer",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      classe_id: {
        required: helpers.withMessage("Veuillez choisir la classe", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.getSlices();
  },

  methods: {
    initPayment() {
      this.spanView = true;
      const self = this;
      this.$store
        .dispatch("postRequest", {
          route: `api/school-fees-payment`,
          data: this.slice,
        })
        .then(
          function (response) {
            self.spanView = false;
            Swal.fire(
              response.data.message,"Payement initialiser, veuillez confirmer le payement sur votre téléphone!","success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("fr-FR", options);
    },
    getSlices() {
      const self = this;
      this.data.classe_id = this.$route.params.class;
      this.data.etudiant = this.$route.params.student;

      this.$store
        .dispatch("getRequest", {
          route: `api/StudentSliceListing/${this.data.classe_id}/${this.data.etudiant}`,
          data: this.data,
        })
        .then(
          function (response) {
            self.slices = response.data;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },

    getSlice(tranche) {
      this.slice = tranche;
      this.slice.etudiant = this.$route.params.student;
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.slices.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" placeholder="Search..." />
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" scope="col" data-sort="currency_name">Tranche</th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Montant
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Date d'échéance
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Etat
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="list form-check-all">
                <tr v-for="(slice, index) in slices" :key="index">

                  <td>{{ slice.slice_name }} </td>
                  <td>{{ slice.amount }} FCFA</td>
                  <td>{{ formatDate(slice.account_date) }} </td>
                  <td v-if="slice.status == '1'" class="text-success text-bold">Payé</td>
                  <td v-else class="text-danger text-bold">Non payé</td>

                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target=".bs-paygate-payment"
                        @click="getSlice(slice)"
                        class="link-success fs-15"
                        ><i class="las la-money-bill-wave"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade bs-paygate-payment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="updateFacultyModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-md">
          <form @submit.prevent="initPayment">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateFacultyModal">
                  Payement d'écolage par mobile
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="phone_number" class="form-label"
                        >Numéro de payement</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="phone_number"
                        placeholder="Numéro de payement"
                        v-model="slice.phone_number"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="level" class="form-label"
                        >Réseau</label
                      >
                      <Multiselect
                          class="form-control"
                          v-model="slice.network"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          placeholder="Réseau"
                          :options="[
                            { value: 'TMONEY', label: 'Togocom' },
                            { value: 'FLOOZ', label: 'Moov' },
                          ]"
                        />
                    </div>
                  </div>
                </div>
                <!--end row-->
              </div>
              <div class="modal-footer">
                <a
                  href="javascript:void(0);"
                  class="btn btn-link link-success fw-medium"
                  data-bs-dismiss="modal"
                >
                  <i class="ri-close-line me-1 align-middle"></i> Close
                </a>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="spanView"
                >
                  <span
                    v-if="spanView"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  Payer
                </button>
              </div>
            </div>
          </form>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </Layout>
</template>
